@import "styles/main.scss";

.myButton {
  width: max-content;
  display: flex;
  align-items: center;

  :global {
    a {
      color: $white;

      @include responsive(normal) {
        &:hover {
          color: $white;
        }
      }
    }

    .button-content {
      padding: $space-4 $space-10;
      border-radius: $radius-sm;
      cursor: pointer;
      transition: 0.3s all;
      background-color: $primary;
      font-weight: 600;
      color: $white;
      border: none;

      @include responsive(normal) {
        &:hover {
          background-color: $second;
        }
      }

      &--second {
        background-color: $grey-black;

        @include responsive(normal) {
          &:hover {
            background-color: $grey2;
          }
        }
      }

      &--small {
        padding: $space-2 $space-10;
        font-size: 1.6rem;
        line-height: 2.4rem;
      }
    }
  }
}
