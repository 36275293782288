@import "styles/main.scss";

.download {
  // height: 80rem;
  background: linear-gradient(180deg, #203030 0%, #0a0a0a 100%);
  // padding-top: 16rem;
  position: relative;
  z-index: 0;
  padding-top: 17rem;

  @include responsive(tablet) {
    padding-top: 0;
  }

  :global {
    .download-screen {
      height: 100vh;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;

      @include responsive(tablet) {
        justify-content: center;
      }

      .section-7 {
        min-height: 100%;
        margin-top: 0;

        .content-body {
          h2 {
            margin-bottom: 5rem;

            @include responsive(normal) {
              padding-top: 0;
            }

            @include responsive(mac-16) {
              margin-bottom: 16rem;
            }
          }
        }
      }
    }

    .img-wave {
      width: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 21rem;

      @include responsive(tablet) {
        bottom: 8%;
      }
    }
  }
}
