@font-face {
  font-family: "Anuphan";
  src: url("../assets/font-family/Anuphan-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Anuphan";
  src: url("../assets/font-family/Anuphan-SemiBold.ttf");
  font-weight: 600;
}
