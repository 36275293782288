@import "styles/main.scss";

.landingBody {
  :global {
    .body-content {
      h3 {
        text-align: center;

        span {
          color: $second;
        }
      }

      .title-text {
        @include container;
        text-align: center;
        margin-bottom: $space-24;

        @include responsive(mac-16) {
          margin-bottom: 0;
        }
      }

      .section-body {
        background-color: $black;
        top: 15%;
        position: relative;
        z-index: 0;

        @include responsive(small) {
          position: sticky;
          top: 10%;
          box-shadow: 0px -80px 80px $black;
        }
      }

      .height-body {
        min-height: calc(100vh - 9.6rem);
        margin-bottom: $space-20;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
      }

      .img-size {
        width: 40%;
      }

      .content-body {
        @include responsive(small) {
          transform: scale(0.9);
        }

        @include responsive(mac-16) {
          transform: scale(1);
        }

        @include responsive(screen2k) {
          transform: scale(1.3);
        }
      }

      .section-2 {
        margin-bottom: 0;
        box-shadow: none;

        @include responsive(small) {
          margin-bottom: 70rem;
        }

        .content-body {
          @include container;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }

        .wallet-action {
          width: 39rem;
          margin-bottom: $space-10;
          z-index: -1;

          @include responsive(screen2k) {
            transform: scale(1.3);
          }
        }

        h3 {
          text-align: center;
        }
      }

      .section-3 {
        margin-bottom: 0;

        @include responsive(small) {
          margin-bottom: 50rem;
        }

        @include responsive(mac-16) {
          margin-bottom: 20rem;
        }

        .content-body {
          @include container;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          @include responsive(screen2k) {
            transform: scale(1);
          }

          .token-sei {
            position: absolute;
            z-index: 10;

            &--token-1 {
              width: 20%;
              left: -10%;
              top: calc(55rem - 100vh);
              animation: upDown 2.5s linear infinite;

              @include responsive(small) {
                top: calc(-40rem - 100vh);
                width: 10%;
                left: 27%;
              }

              @include responsive(mac-16) {
                top: calc(-25rem - 100vh);
                width: 10%;
                left: 27%;
              }

              @include responsive(screen2k) {
                top: calc(-25rem - 100vh);
                transform: scale(1);
                width: 18%;
                left: 5%;
              }
            }

            &--token-2 {
              width: 35%;
              right: -17%;
              top: calc(25rem - 100vh);
              animation: upDown 3s linear infinite;

              @include responsive(small) {
                top: calc(-60rem - 100vh);
                width: 15%;
                right: 24%;
              }

              @include responsive(mac-16) {
                top: calc(-45rem - 100vh);
                width: 15%;
                right: 24%;
              }

              @include responsive(screen2k) {
                top: calc(-55rem - 100vh);
                width: 30%;
                right: 0%;
              }
            }
          }

          .bg-nft {
            position: absolute;
            top: 45%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 120rem;
            z-index: -1;

            @include responsive(small) {
              width: 80%;
            }

            @include responsive(screen2k) {
              width: 150%;
            }
          }

          .img-content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            @include responsive(screen2k) {
              transform: scale(1.8);
            }

            .bg-content {
              width: 39rem;
              margin-bottom: $space-10;

              @include responsive(small) {
                margin-bottom: 0;
                width: 30%;
              }

              @include responsive(mac-16) {
                transform: translateY(0);
              }
            }
          }

          h3 {
            @include responsive(small) {
              transform: translateY(-1rem);
            }

            @include responsive(screen2k) {
              transform: scale(1.3) translateY(10rem);
            }
          }
        }
      }

      .section-4 {
        @include responsive(small) {
          margin-bottom: 50rem;
        }

        .content-body {
          @include container;
          height: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          margin-bottom: 0;
          position: relative;
          z-index: 0;

          .img-nft {
            position: absolute;
            z-index: 10;
            display: none;

            @include responsive(small) {
              display: block;
            }

            &--nft-1 {
              width: 20%;
              left: -10%;
              top: calc(35rem - 100vh);
              animation: upDown 2.5s linear infinite;

              @include responsive(small) {
                top: calc(-30rem - 100vh);
                width: 25%;
                left: 5%;
              }

              @include responsive(normal) {
                width: 20%;
                left: 20%;
              }

              @include responsive(mac-16) {
                top: calc(10rem - 100vh);
              }

              @include responsive(screen2k) {
                top: calc(45rem - 100vh);
                transform: scale(1);
                width: 25%;
                left: 10%;
              }
            }

            &--nft-2 {
              width: 35%;
              right: -17%;
              top: calc(15rem - 100vh);
              animation: upDown 3s linear infinite;

              @include responsive(small) {
                top: calc(-40rem - 100vh);
                width: 35%;
                right: 0%;
              }

              @include responsive(normal) {
                width: 25%;
                right: 20%;
              }

              @include responsive(mac-16) {
                top: calc(5rem - 100vh);
              }

              @include responsive(screen2k) {
                top: calc(25rem - 100vh);
                width: 35%;
                right: 5%;
              }
            }
          }

          // @include responsive(normal) {
          //   margin-bottom: 20rem;
          // }

          .img-send {
            width: 39rem;
          }
        }
      }

      .section-5 {
        @include responsive(small) {
          margin-bottom: 80vh;
        }

        @include responsive(fhd) {
          margin-bottom: 0;
        }

        .content-body {
          position: relative;
          @include container;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;

          @include responsive(screen2k) {
            transform: translateY(-10%) scale(1.1);
          }

          .img-send-action {
            position: absolute;
            width: 30rem;
            top: -160vh;
            display: none;

            @include responsive(small) {
              display: block;
              top: -150vh;
            }

            @include responsive(mac-13) {
              top: -150vh;
            }

            @include responsive(mac-16) {
              top: -120vh;
            }

            @include responsive(screen2k) {
              top: -95vh;
            }

            &--send-1 {
              left: -30rem;

              @include responsive(mac-13) {
                left: 0;
              }
            }

            &--send-2 {
              right: -30rem;

              @include responsive(mac-13) {
                right: 0;
              }
            }
          }

          .img-security {
            margin-bottom: $space-6;
            width: 75rem;

            @include responsive(small) {
              width: 80vh;
            }

            @include responsive(mac-16) {
              width: 60vh;
            }
          }
        }
      }

      .section-6 {
        margin-bottom: 20rem;

        @include responsive(small) {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .content-body {
          @include container;
          position: relative;
          z-index: 0;

          // @include responsive(normal) {
          //   padding-top: 5rem !important;
          // }

          // @include responsive(fhd) {
          //   padding-bottom: 15rem !important;
          // }

          &::after {
            content: "";
            width: 100rem;
            height: 100%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background: radial-gradient(50% 50% at 50% 50%, #1c2929 0%, #0a0a0a 100%);
            z-index: -1;

            @include responsive(small) {
              width: 100%;
            }
          }

          h2 {
            text-align: center;

            @include responsive(small) {
              margin-bottom: $space-20;
            }
          }

          .list-ecosystem {
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            gap: $space-6 $space-4;
            margin-top: $space-8;

            @include responsive(small) {
              margin-top: 0;
            }

            .item-eco {
              display: flex;
              align-items: center;
              gap: $space-4;
              padding: $space-4;
              padding-right: $space-8;
              border-radius: 9.6rem;
              background-color: rgba($white, 0.02);
              width: 100%;

              @include responsive(small) {
                width: calc(50% - 1.6rem);
              }

              @include responsive(small) {
                width: unset;
              }

              .img-icon {
                width: 4rem;
                height: 4rem;
                border-radius: 50%;

                @include responsive(small) {
                  width: 6.4rem;
                  height: 6.4rem;
                }
              }

              &--text {
                color: $grey;
                font-weight: 600;
              }
            }
          }

          .action-btn {
            display: flex;
            justify-content: center;
            margin-top: $space-16;

            @include responsive(small) {
              margin-top: $space-16;
            }
          }
        }
      }
    }
  }
}
