@import "styles/main.scss";

.banner {
  height: 80rem;
  background: linear-gradient(180deg, #203030 0%, #0a0a0a 100%);
  padding-top: 16rem;
  margin-bottom: 10%;
  position: relative;
  z-index: 0;

  @include responsive(mac-16) {
    padding-top: 9.6rem;
    height: calc(100vh - 9.6rem);
  }

  :global {
    .content {
      @include container;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;

      @include responsive(screen2k) {
        transform: scale(1.3);
      }

      h1 {
        span {
          color: $second;
        }
      }

      .text-description {
        color: $grey;
        margin-top: $space-6;
        margin-bottom: $space-12;
        @include textBody;

        @include responsive(tablet) {
          width: 70%;
        }
      }

      .img-available {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 39rem;
        z-index: -1;

        @include responsive(tablet) {
          top: 45%;
        }
      }
    }

    .bg-wave {
      width: 100%;
      position: absolute;
      left: 50%;
      bottom: 10%;
      transform: translateX(-50%);
      z-index: 2;

      @include responsive(tablet) {
        bottom: 20%;
      }

      @include responsive(mac-16) {
        bottom: 25%;
      }

      @include responsive(mac-16) {
        bottom: 20%;
      }

      .img-wave {
        width: 100rem;
        position: absolute;

        @include responsive(tablet) {
          width: 100%;
        }
      }
    }
  }
}
