@import "styles/main.scss";

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: rgba(10, 10, 10, 0.02);
  z-index: 999;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    backdrop-filter: blur(3.2rem);
    z-index: -1;
  }

  :global {
    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .download-app {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $space-4 $space-5;
      background-color: $black;

      &--left {
        display: flex;
        align-items: center;
        gap: $space-3;

        .img-logo-app {
          width: 4.8rem;
        }

        .text-title {
          .text-description {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }

      .button-custom {
        .button-content {
          padding: $space-2 1rem;
        }
      }
    }

    .header-content {
      padding: $space-6 $space-5;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;

      @include responsive(tablet) {
        padding: $space-6 $space-10;
      }

      @include responsive(fhd) {
        max-width: 1920px;
      }

      &--left,
      &--center,
      &--right {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &--left {
        .img-logo {
          width: 16rem;

          &--mobile {
            width: 3.2rem;
            height: 3.2rem;
            border-radius: 50%;
          }
        }
      }

      &--center {
        gap: $space-16;
        display: none;
        font-size: 1.8rem;
        font-weight: 600;

        @include responsive(tablet) {
          display: flex;
        }
      }

      &--right {
        .icon-menu {
          width: 2.4rem;
        }
      }
    }

    .header-mobile {
      width: 100%;
      padding: 0 $space-5;
      transition: 0.5s all;
      z-index: -1;
      height: 0;
      overflow: hidden;

      &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        backdrop-filter: blur(3.2rem);
        z-index: -1;
      }

      &--open {
        height: 6rem;
      }

      .content-menu-mobile {
        height: 6rem;
        display: flex;
        align-items: center;
        gap: $space-8;
      }
    }
  }
}
