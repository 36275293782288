@import "styles/main.scss";

.footer {
  width: 100%;
  padding: $space-6 0;
  border-top: 1px solid $grey-black;
  position: absolute;
  bottom: 0;

  :global {
    .content-footer {
      @include container;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;

      @include responsive(tablet) {
        flex-direction: row;
      }

      .logo {
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
      }

      .text-footer {
        font-size: 1.6rem;
        color: $grey;
      }

      .menu-ft {
        display: flex;
        align-items: center;
        gap: $space-5;
        margin: $space-10 0;

        @include responsive(tablet) {
          margin: 0;
          gap: $space-8;
        }

        @include responsive(normal) {
          gap: $space-16;
        }

        .item-menu {
          font-size: 1.8rem;
          font-weight: 600;
        }
      }

      .social-icon {
        display: flex;
        align-items: center;
        gap: $space-10;

        .item-social {
          display: flex;
          align-items: center;
          justify-content: center;

          .icon-img {
            transition: 0.3s all;

            &:hover {
              transform: scale(1.2);
            }
          }
        }
      }
    }
  }
}
