h1 {
  font-size: 4.8rem;
  line-height: 6.4rem;
  font-weight: 600;
  margin: 0;

  @include responsive(tablet) {
    font-size: 6.4rem;
    line-height: 8rem;
  }
}

h2 {
  font-size: 3.2rem;
  line-height: 4rem;
  font-weight: 600;
  margin: 0;

  @include responsive(tablet) {
    font-size: 4.8rem;
    line-height: 6.4rem;
  }
}

h3 {
  font-size: 3.2rem;
  line-height: 4rem;
  font-weight: 600;
  margin: 0;
}

h4 {
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-weight: 600;
  margin: 0;
}

h5 {
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-weight: 600;
  margin: 0;
}

h6 {
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 600;
  margin: 0;
}

.text-body-1 {
  font-size: 4rem;
  line-height: 5.6rem;
}

.text-body-3 {
  font-size: 1.6rem;
  line-height: 2.4rem;
}
