@import "styles/main.scss";

.downloadScreen {
  :global {
    .section-7 {
      min-height: 100vh;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;

      @include responsive(tablet) {
        justify-content: center;
        margin-top: 0;
      }

      .content-body {
        @include container;

        @include responsive(screen2k) {
          transform: scale(1);
        }

        h1 {
          margin-bottom: 5rem;

          // @include responsive(normal) {
          //   padding-top: 16vh;
          // }

          @include responsive(mac-16) {
            padding-top: 0;
            margin-bottom: 16rem;
          }
        }

        .list-version {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;

          &--browser {
            margin-bottom: $space-10;
          }

          h3 {
            margin-bottom: $space-8;
            text-align: left;
            color: $grey;
            display: flex;
            gap: $space-3;
            align-items: center;

            .badge-coming {
              padding: $space-2;
              border: 1px solid $white;
              border-radius: $radius-sm;
              color: $white;
              font-size: 1.4rem;
              line-height: 2rem;
            }
          }

          .list-item {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: $space-6;

            .item-version {
              gap: $space-4;
              padding: $space-4;
              padding-right: $space-8;
              border-radius: 9.6rem;
              background-color: rgba($white, 0.02);
              cursor: pointer;
              transition: 0.3s all;
              display: flex;
              align-items: center;

              &:hover {
                background-color: rgba($white, 0.2);
                color: $white;
              }

              a {
                display: flex;
                align-items: center;
                gap: $space-4;
              }

              .item-icon {
                width: 4rem;
                height: 4rem;
                border-radius: 50%;
              }

              .text-download {
                font-weight: 600;
                color: $grey;
              }
            }

            &--disable {
              pointer-events: none;
            }
          }
        }
      }
    }
  }
}
