// space
$space-1: 4px;
$space-2: 8px;
$space-2-5: 1rem;
$space-3: 1.2rem;
$space-4: 1.6rem;
$space-5: 2rem;
$space-6: 2.4rem;
$space-8: 3.2rem;
$space-10: 4rem;
$space-12: 4.8rem;
$space-16: 6.4rem;
$space-20: 8rem;
$space-24: 9.6rem;

$space-52: 5.2rem;

$height-header: 6.5rem;
$mt-header: 11.2rem;

// radius
$radius: 4px;
$radius-sm: 8px;
$radius-sm1: 1.2rem;
$radius-md: 1.6rem;
$radius-lg: 2rem;
$radius-lg2: 2.4rem;
$radius-xl: 3.2rem;