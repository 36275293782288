@import "./main.scss";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  font-family: "Anuphan", sans-serif;
  background: $black;
  color: $white;
  scroll-behavior: smooth;
  overflow-x: hidden;

  @include responsive(normal) {
    overflow-x: unset;
  }
}

html {
  // font-size: 37.5%;
  min-height: 100%;
  height: auto;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  font-size: 56% !important;
  scrollbar-width: thin;

  @include responsive(mac-13) {
    font-size: 62.5% !important;
  }
}

body {
  width: 100%;
  // overflow: hidden;
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 3.2rem;
}

a {
  color: $white;
  text-decoration: none;
  transition: 0.3s all;
  cursor: pointer;

  &:hover {
    color: $second;
  }
}

.top-header {
  padding-top: 9.6rem;
}

img {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
